.header {

    
    display: flex;
    position: relative;
    /* top: 0;
    left: 0;
    right: 0;
    z-index: 9999; */
    opacity: 90%;
    padding: 0;
    font-family: 'Cormorant', serif;
}

.header h1 {
    margin-left: 20px;
    letter-spacing:.5rem ;
}

.header nav {
    margin-right: 20px;
}

.icons {
    display: none !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* color: black; */
    font-size: 20px;
}

.mobile-menu {
    display: none !important;
    flex-direction: flex-end;
    justify-content: center;
    

}

.mobile-nav {
    display: none !important;
    flex-direction: column;
    justify-content: center;
    top: 40px;
    text-align: center;
}

.mobile-nav ul {
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
     
}

.ul.mobile-links {
    display: flex;
    
}

.mobile-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    transform: translate(-50%, 0);
    list-style: none;
   margin-left: -20%;
    margin-right: 20%;
    margin-bottom: px;
    margin-top: 40px;
}

.mobile-links a {
    padding: 5px;
}

.mobile-links li {
    border-radius:  5px!important;
}




@media (max-width: 768px) {
    .header {
        display: flex;
        flex-direction: row ;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding:60px;
        /* padding-top: 30px; */

    }

    .header h1 {
     display: flex !important;
     flex-direction: row;
     margin-left: 0;
    letter-spacing: 0;
    }

    .header nav {
        display: flex;
        flex-direction: column;
        
    }

    .header nav a {
        display: flex;
        font-size: small;
    }

    .desktop-nav {
        display: none !important;
    }

    .mobile-menu {
        display: block !important;
    }

.icons {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
}

.mobile-nav {
    display: flex !important;
    flex-direction: column;
   
    
}

}