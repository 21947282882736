.footer {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 50px;
    background-color: #333;
    color: #f2f2f2;
    opacity: 90%;
    font-family: 'Cormorant', serif;
}

.footer p {
    display: flex;
    margin-left: 20px;
}

.header-social-links {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
}

.header-social-links a {
    margin-left: 30px;
}

.header-social-links img {
    width: 40px;
    height: 40px;
}

@media (max-width: 768px) {
.footer {
    display: flex;
    font-size: small;
}

    .header-social-links {
            display: flex;
            flex-direction: row;
            align-items: center;
    
        }
    
        .header-social-links img {
            display: flex;
            width: 20px;
            height: 20px;
        }
    
        .header-social-links ul {
            font-size: small;
        }
}