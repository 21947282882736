.form {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
}

.contact {
    font-family: 'Cormorant', serif;
}

.modal {
    display: flex;
    justify-content:center;
    text-align: center;
    align-content: center;
}

.modal-header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    padding: 1rem;
}

.modal-title {
    font-size: 1.5rem ;
    font-weight: bold;
    color: #333;
}

.modal-body {
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    padding: 1rem;
    text-align: center;
    font-size: 20px;
}

.button {
    font-family: 'Cormorant', serif;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    font-weight: bold;
       
}

.btn-primary:hover {
    background-color: #0069d9;
    border-color: 0062cc;
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff;
    font-size: 20px;
    
}

.btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}

@media (max-width: 768px) {
    .contact h2 {
        font-size: medium;
    }

    .form button {
        font-size: small;
    }


}