/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App {
  font-family: 'Lato', sans-serif;
}

body {
  font-family: sans-serif;
  color: #333;
  background-color: #f2f2f2;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: #f2f2f2;
}

header h1 {
  margin-left: 50px;
  /* font-size: 30px; */
  font-weight: 500;
}

header nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

header nav a {
  color: #f2f2f2;
  text-decoration: none;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 500;
}

header nav a:hover {
  color: black;
}

section {
  padding: 20px;
}

h2 {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  width: 100%;

}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px #333;
}

form label,
form input,
form textarea,
form button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
}

form input[type="text"],
form input[type="email"] {
  background-color: #fff;
}

form textarea {
  height: 150px;
  background-color: #fff;
}

form button {
  background-color: #333;
  color: #f2f2f2;
  border: none;
  cursor: pointer;
}
