.about {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    /* color: #292020; */
    font-family: 'Cormorant', serif;
    color: black;

}

.about h2 {
    font-size: 36px;
    margin-bottom: 30px;
    color: #3F3F3F;
}


.slick-slide img {
    width: 80%;
    height: 400px;
    object-fit:contain;
    margin-bottom: 30px;
    filter:grayscale(0.9);
  

}


.slick-next:before, .slick-prev:before {
    color: black;

}


.slick-dots li button:before {
    color: #3F3F3F;
}

.about p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 30px;
    color: black;
}

@media (max-width :767px) {
.about {
    font-size: small;
}

.about p {
    font-size: small;
}

.about h2 {
    font-size: medium;
}

.slick-slide img {
    max-height: 200px!important;
    gap: 10px;

}

.slick-dots li button {
    display: none;
}

}